import './App.css';
import BannerImage from './assets/BannerImage.jpg'
import LogoLarge from  './assets/ndzmlg.png'

function App() {
  return (
    <div className="App">
      <div className='h-screen w-full relative'>
        <img src={BannerImage} alt="banner" className='h-full w-full object-cover'/>
        <div className='absolute inset-0 h-full  w-full flex flex-col  justify-center items-center'>
          <div className='w-2/3 max-w-xs md:max-w-md lg:max-w-lg'>
            <img src={LogoLarge} alt="logo" className='h-full w-full object-contain'/>
          </div>
          <div className='text-white'>
            <h1 className='text-xl md:text-3xl tracking-wide' style={{fontFamily:'Mundial', fontWeight:'400'}}>Revamping the Future of Learning</h1>
            <h1 className='text-3xl md:text-6xl  text-center tracking-wide' style={{fontFamily:'Mundial', fontWeight:'700'}}>Stay Tuned!</h1>
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;
